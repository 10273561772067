import React, { useEffect, useState } from 'react';
import CustomContainer from 'components/CustomContainer';
import { Link, navigate } from 'gatsby';
import { useWindowWidth, useMediaQuery } from 'components/hooks/useResponsive';

import Next from 'images/ecosystem/carousel-next-button.svg';
import Prev from 'images/ecosystem/carousel-prev-button.svg';
import LEFT_ARROW_ICON from 'images/left-arrow.png';
import { useLocation } from '@reach/router';

const CompanyDetailsText = ({ title, description, isLink }) => (
  <div className="mb-8 mr-6">
    <div className="text-sm">{title}</div>
    <div
      className={`text-sm lg:text-base font-extralight ${
        isLink && 'underline hover:opacity-80 cursor-pointer'
      }`}
      onClick={() => window.open(`${description}`)}
    >
      {description}
    </div>
  </div>
);

const EcosystemDetails = ({ ecosystem, allEcosystem }) => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  useEffect(() => {
    allEcosystem?.forEach((e, i) => {
      if (e?.node?.slug === ecosystem?.slug) {
        setSelectedIndex(i);
      }
    });
  }, [allEcosystem, ecosystem?.slug]);

  const nextSlideFormula =
    selectedIndex === allEcosystem.length - 1 ? 0 : selectedIndex + 1;

  const prevSlideFormula =
    selectedIndex === 0 ? allEcosystem.length - 1 : selectedIndex - 1;

  return (
    <div className="bg-black text-[#fff] h-full z-[-20]">
      <div className="w-full h-[30vh] lg:h-auto z-[-10] border-b-[14px] border-[#FF4C00] lg:border-0">
        <img
          alt="banner"
          src={ecosystem?.bannerImage}
          className="w-full h-full max-h-[50vh] object-cover object-bottom"
        />
      </div>
      {windowWidth >= mediaQuery.lg && (
        <CustomContainer navbar>
          <Link to="/ecosystem">
            <div className="flex items-center cursor-pointer hover:underline duration-300 absolute top-[90px]">
              <img
                src={LEFT_ARROW_ICON}
                alt="back-arrow"
                className="h-[12px] mr-3"
              />
              <p className="text-lg font-light">Return to Ecosystem</p>
            </div>
          </Link>
        </CustomContainer>
      )}

      <div className="lg:mt-[-10vh]">
        <CustomContainer>
          <div className="flex flex-row items-center relative">
            <div
              className="absolute top-[-13vh] left-0 lg:top-auto lg:left-[-15px] lg:left-[-50px]"
              onClick={() =>
                navigate(
                  `/ecosystem/${allEcosystem[prevSlideFormula]?.node?.slug}`,
                )
              }
            >
              <img
                className="w-[50px] sm:w-[70px] xl:w-[90px] cursor-pointer hover:border rounded-full"
                src={Prev}
                alt="prev"
              />
            </div>
            <div
              className="flex flex-col lg:flex-row lg:bg-[#151515] lg:px-[100px] py-10 lg:py-[90px] 
              lg:rounded w-full lg:mx-16 lg:border-t-[16px] lg:border-[#FF4C00]"
            >
              {windowWidth < mediaQuery.lg && (
                <Link to="/ecosystem">
                  <div className="flex items-center cursor-pointer hover:underline duration-300">
                    <img
                      src={LEFT_ARROW_ICON}
                      alt="back-arrow"
                      className="h-[10px] mr-3"
                    />
                    <p className="text-sm font-light">Return to Ecosystem</p>
                  </div>
                </Link>
              )}
              <div className="w-[60%] sm:w-[35%] lg:w-[35%] flex lg:justify-center lg:items-center lg:mr-16 my-8 mb-5 lg:my-0">
                <img
                  alt="logo-company"
                  src={ecosystem?.logoImage}
                  className="w-auto lg:w-[250px] h-[100px] lg:h-[150px] object-contain"
                />
              </div>
              <div className="flex flex-col lg:w-[65%] lg:ml-10">
                <div className="grid grid-cols-[repeat(1,_1fr)] sm:grid-cols-[repeat(2,_1fr)]">
                  {ecosystem?.website !== '' ? (
                    <CompanyDetailsText
                      title={'Website'}
                      description={ecosystem?.website}
                    />
                  ) : null}
                  {ecosystem?.entry !== '' ? (
                    <CompanyDetailsText
                      title={'Date of Entry'}
                      description={ecosystem?.entry}
                    />
                  ) : null}
                  {ecosystem?.area !== '' ? (
                    <CompanyDetailsText
                      title={'Area'}
                      description={ecosystem?.area}
                    />
                  ) : null}
                  {ecosystem?.location !== '' ? (
                    <CompanyDetailsText
                      title={'Location'}
                      description={ecosystem?.location}
                    />
                  ) : null}
                </div>
                <div className="text-base font-light">
                  {isEnglish || ecosystem?.descriptionId === null
                    ? ecosystem?.description
                    : ecosystem?.descriptionId}
                </div>
              </div>
            </div>
            <div
              className="absolute top-[-13vh] right-0 lg:top-auto lg:right-[-15px] xl:right-[-50px]"
              onClick={() =>
                navigate(
                  `/ecosystem/${allEcosystem[nextSlideFormula]?.node?.slug}`,
                )
              }
            >
              <img
                className="w-[50px] sm:w-[70px] xl:w-[90px] cursor-pointer hover:border rounded-full"
                src={Next}
                alt="next"
              />
            </div>
          </div>

          <div className="lg:mx-16">
            <div className="text-2xl pt-14 pb-8">Gallery</div>
            <div className="grid grid-cols-[repeat(2,_1fr)] lg:grid-cols-[repeat(4,_1fr)] gap-3 sm:gap-5 pb-24">
              {ecosystem?.imageGallery.map((image, index) => (
                <img
                  src={image}
                  className="object-cover w-full h-[120px] sm:h-[180px]"
                  alt={`gallery-${index + 1}`}
                />
              ))}
            </div>
          </div>
        </CustomContainer>
      </div>
    </div>
  );
};

export default EcosystemDetails;
