import React from 'react';
import Pages from 'components/Pages';
import EcosystemDetails from 'components/pages/Ecosystem/EcosystemDetails';
import { graphql } from 'gatsby';

const EcosystemDetailsPage = ({ data }) => {
  return (
    <Pages title="FOLK - Ecosystem">
      <EcosystemDetails
        ecosystem={data.ecosystemJson}
        allEcosystem={data.allEcosystemJson.edges}
      />
    </Pages>
  );
};

export default EcosystemDetailsPage;

export const query = graphql`
  query ($slug: String) {
    ecosystemJson(slug: { eq: $slug }) {
      id
      slug
      displayImage
      logoImage
      category
      description
      descriptionId
      bannerImage
      website
      name
      imageGallery
      area
      location
      entry
    }
    allEcosystemJson {
      edges {
        node {
          id
          slug
          displayImage
          logoImage
          category
          description
          descriptionId
          bannerImage
          website
          name
          imageGallery
          area
          location
          entry
        }
      }
    }
  }
`;
